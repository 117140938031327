import { Uploader } from "vant";
import html2canvas from "html2canvas";
export default {
  name: "HomeView",
  components: {
    Uploader
  },

  setup() {
    const generate = () => {
      const dom = document.getElementById("dom"); // 创建一个新的canvas

      const Canvas = document.createElement("canvas");
      const width = document.body.offsetWidth; // 可见屏幕的宽

      const height = document.body.offsetHeight; // 可见屏幕的高

      const scale = window.devicePixelRadio || 1; // 设备的devicePixelRadio
      // 将Canvas画布放大scale倍，然后放在小的屏幕里，解决模糊问题

      Canvas.width = width * scale;
      Canvas.height = height * scale;
      Canvas.getContext("2d").scale(scale, scale);
      html2canvas(dom, {
        canvas: Canvas,
        scale,
        useCORS: true,
        logging: true,
        width: width + "px",
        hegiht: height + "px",
        backgroundColor: "#fff"
      }).then(canvas => {
        // const context = canvas.getContext("2d");
        // // 关闭抗锯齿形
        // context.mozImageSmoothingEnabled = false;
        // context.webkitImageSmoothingEnabled = false;
        // context.msImageSmoothingEnabled = false;
        // context.imageSmoothingEnabled = false;
        // canvas转化为图片
        //const img = canvas2Image(canvas, canvas.width, canvas.height);
        downLoadImage(canvas, "报告");
      });
    }; // const canvas2Image = (canvas, width, height) => {
    //   const retCanvas = document.createElement("canvas");
    //   const retCtx = retCanvas.getContext("2d");
    //   retCanvas.width = width;
    //   retCanvas.height = height;
    //   retCtx.drawImage(canvas, 0, 0, width, height, 0, 0, width, height);
    //   const img = document.createElement("img");
    //   img.src = retCanvas.toDataURL("image/jpeg"); // 可以根据需要更改格式
    //   return img;
    // };


    const downLoadImage = (canvas, name) => {
      alert(122);
      var a = document.createElement("a");
      a.href = canvas.toDataURL();
      a.download = name;
      a.click();
    };

    return {
      generate
    };
  }

};